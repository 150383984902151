<template>
    <div>
        <div
            class="infinite-list"
            v-infinite-scroll="load"
            style="overflow: auto; height: 100%; position: absolute; width: 100%"
            :infinite-scroll-distance="100"
            :infinite-scroll-disabled="disabledLoadFlag"
        >
            <el-card style="text-align: center" v-if="emptyFlag"> 暂无核销记录 </el-card>
            <el-card class="box-card infinite-list-item" v-for="result in pageResult" :key="result.id">
                核销积分成功<br />
                核销门店:{{ result.deptName }}<br />
                核销积分:{{ result.point }}<br />
                核销时间:{{ result.time }}<br />
            </el-card>
            <el-card style="text-align: center; margin-bottom: 20px" v-if="fullFlag"> 没有更多了 </el-card>
        </div>
    </div>
</template>
<script type="text/javascript">
import Util from 'js/Util';

export default {
    name: 'WechatPoint',
    data() {
        return {
            form: {
                memberCode: '',
                page: 1,
                limit: Util.Limit,
            },
            pageResult: [],
            // 一共有多少页
            pageNums: 1,
            count: 0,
            emptyFlag: false,
            fullFlag: false,
            disabledLoadFlag: false,
        };
    },
    mounted() {},
    created() {
        document.title = '会员积分';
        const param = this.$route.query;
        this.form.memberCode = param.memberCode;
        history.pushState(null, null, document.URL);
        window.addEventListener('popstate', () => {
            history.pushState(null, null, document.URL);
        });
    },
    destroyed() {
        // 清除popstate事件 否则会影响到其他页面
        window.removeEventListener('popstate');
    },
    methods: {
        async load() {
            this.disabledLoadFlag = true;
            // 总页数大于当前页
            if (this.pageNums >= Math.ceil(this.form.page)) {
                await this.$http.get('/wechat/point/page', { params: this.form }).then((res) => {
                    const list = res.data.data.second;
                    if (list.length > 0) {
                        for (let i = 0; i < list.length; i++) {
                            this.$set(this.pageResult, this.pageResult.length, list[i]);
                        }
                        this.pageNums = Math.ceil(res.data.data.first / Util.Limit);
                        this.form.page = this.form.page + 1;
                        this.emptyFlag = false;
                    } else {
                        this.emptyFlag = true;
                    }
                });
                this.fullFlag = false;
            } else {
                this.fullFlag = true;
            }
            this.disabledLoadFlag = false;
        },
    },
};
</script>
